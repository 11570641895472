<template>
    <ProjectLayout>
        <!-- Hero section -->
        <template #navigation>
            <div class="relative bg-gray-900">
                <!-- Decorative image and overlay -->
                <div
                    aria-hidden="true"
                    class="absolute inset-0 overflow-hidden"
                >
                    <img
                        :src="project.settings?.branding?.header_image_url"
                        :alt="project.name"
                        class="object-cover object-center w-full h-full"
                    />
                </div>

                <div
                    aria-hidden="true"
                    class="absolute inset-0 bg-gray-900 opacity-50"
                />

                <!-- Navigation -->
                <Navigation navbar-style="dark" />

                <div class="relative max-w-3xl mx-auto px-4 flex flex-col items-center text-center justify-center sm:px-12 lg:px-0 h-[394px] sm:h-[414px]">
                    <h1 class="text-4xl font-extrabold tracking-tight text-white lg:text-5xl">{{ project.name }}</h1>
                    <p class="mt-4 text-xl text-white">{{ project.short_description }}</p>

                    <div
                        v-if="project.settings.website.cta?.type"
                        class="mt-8"
                    >
                        <component
                            v-if="project.settings.website.cta.type === 'view_menu'"
                            :is="project.settings.website.cta.custom_url ? 'a' : Link"
                            :href="project.settings.website.cta.custom_url ?? route('project.menu', project.domain)"
                            class="inline-block px-8 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-100"
                        >{{ t('see-menu') }}</component>
                        <component
                            v-if="project.settings.website.cta.type === 'book_table'"
                            :is="project.settings.website.cta.custom_url ? 'a' : Link"
                            :href="project.settings.website.cta.custom_url ?? route('project.booking.index', project.domain)"
                            class="inline-block px-8 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-100"
                        >{{ t('book-table') }}</component>
                    </div>
                </div>
            </div>
        </template>

        <div
            v-if="aboutBody"
            class="bg-gray-100"
        >
            <div class="max-w-4xl px-4 py-12 mx-auto sm:py-16 sm:px-6 lg:px-8">
                <div class="text-center">
                    <p class="mb-6 text-xl font-bold text-gray-900 sm:text-2xl sm:tracking-tight">{{ aboutHeader }}</p>
                    <p class="text-base text-gray-500 whitespace-pre-line">{{ aboutBody }}</p>

                    <img
                        :src="project.settings?.branding?.logo_image_url"
                        :alt="project.name"
                        class="inline-block h-10 mt-12"
                    />

                    <template v-if="recommendationBadges.length > 0">
                        <div class="w-1/3 h-px mx-auto mt-12 mb-6 bg-gray-200"></div>

                        <div class="flex flex-wrap items-start justify-center gap-x-4">
                            <component
                                v-for="badge in recommendationBadges"
                                :key="badge.id"
                                :is="badge.link ? 'a' : 'div'"
                                :href="badge.link || undefined"
                            >
                                <img
                                    class="h-20"
                                    :src="badge.image_url"
                                    :alt="`${badge.guide_name} ${badge.year}`"
                                />
                            </component>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <section
            v-if="gallery.length > 0"
            class="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8"
        >
            <h2 class="text-xl font-bold text-center text-gray-900 sm:text-2xl sm:tracking-tight">{{ t('gallery') }}</h2>
            <p class="mt-2 text-sm text-center text-gray-500">{{ t('gallery-description') }}</p>

            <Gallery
                class="mt-8"
                :images="gallery"
            />
        </section>

        <section
            v-if="location"
            class="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8"
        >
            <div class="flex flex-col md:flex-row gap-x-16 gap-y-12">
                <div
                    v-if="location.latitude && location.longitude"
                    class="w-full flex-grow-1"
                >
                    <GMapMap
                        :center="{ lat: location.latitude, lng: location.longitude }"
                        :zoom="17"
                        map-type-id="terrain"
                        :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: true,
                        }"
                        class="overflow-hidden rounded-lg shadow-md"
                        style="width: 100%; height: 400px"
                    >
                        <GMapMarker :position="{ lat: location.latitude, lng: location.longitude }" />
                    </GMapMap>
                </div>

                <div class="md:flex-shrink-0 md:w-[250px] space-y-6">
                    <div v-if="location.address && location.city && location.postcode">
                        <h2 class="mb-4 font-semibold">{{ t('location') }}</h2>

                        <div class="text-sm">
                            <p>{{ location.address }}</p>
                            <p class="text-gray-500">{{ location.postcode }} {{ location.city }}</p>
                        </div>
                    </div>

                    <div v-if="location.legal_name || location.registration_number || location.tax_number || location.phone_number || location.email">
                        <h2 class="mb-4 font-semibold">{{ t('contact') }}</h2>

                        <div class="text-sm">
                            <p v-if="location.phone_number">{{ location.phone_number }}</p>
                            <p v-if="location.email">{{ location.email }}</p>

                            <div
                                v-if="location.legal_name || location.registration_number || location.tax_number"
                                class="my-3"
                            ></div>

                            <p v-if="location.legal_name">{{ location.legal_name }}</p>
                            <p v-if="location.registration_number">Reg kood: {{ location.registration_number }}</p>
                            <p v-if="location.tax_number">KMKR: {{ location.tax_number }}</p>
                        </div>
                    </div>

                    <div v-if="$page.props.project.opening_hours.has_business_hours">
                        <h2 class="mb-4 font-semibold">{{ t('working-hours') }}</h2>

                        <ul class="space-y-1 text-sm text-gray-500">
                            <template
                                v-for="(item, itemIdx) in $page.props.project.opening_hours.business_hours"
                                :key="itemIdx"
                            >
                                <li
                                    v-for="(day, dayIdx) in item.days"
                                    :key="dayIdx"
                                    class="flex justify-between"
                                >
                                    <span class="text-gray-700 capitalize">{{ dayjs.weekdays()[day.dow === 6 ? 0 : day.dow + 1] }}</span>
                                    <span>{{ item.hours.length > 0 ? item.hours.join(', ') : t('closed') }}</span>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </ProjectLayout>
</template>

<script setup>
import dayjs from 'dayjs';
import { defineAsyncComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { Link } from '@inertiajs/vue3';
import ProjectLayout from '@Project/Layouts/ProjectLayout.vue';
import Navigation from '@Project/Components/Navigation.vue';

const Gallery = defineAsyncComponent(() =>
    import(/* webpackChunkName: "chunk-gallery" */ '@Project/Components/Gallery.vue')
);

const props = defineProps([
    'project',
    'location',
    'aboutHeader',
    'aboutBody',
    'recommendationBadges',
    'gallery',
]);

const { t } = useI18n();
</script>
